<template>
  <div class="vuetify-schedules">
    <!-- Se itera sobre los dias -->
    <div v-for="day in currentData" :key="day.key" class="vuetify-schedules-day">
      <div class="vuetify-schedules-day__top">
        <!-- Nombre del día -->
        <span class="label primary--text">
          {{ getDayLabel(day.key) }}
        </span>
        <!-- Cerrar dia -->
        <v-checkbox
          v-model="disabledDays[day.key]"
          :label="disabledDays[day.key] ? closedDayCheckBoxLabel : closeDayCheckBoxLabel"
          dense
          :hide-details="true"
        />
      </div>
      <div v-if="day.data !== null" class="vuetify-schedules-day__bottom">
        <!-- Turnos del día -->

        <div
          v-for="(time, timeIndex) in day.data"
          :key="`${day.key}-${timeIndex}`"
          class="vuetify-schedules-day__times"
        >
          <div
            v-for="(field, fieldIndex) in timeFields"
            :key="`${day.key}-${timeIndex}-${field}`"
            class="vuetify-schedules-day__fields"
          >
            <!-- Partes de un turno -->
            <div style="width: 100%">
              <VueTimepicker
                v-model="time[field]"
                :format="timeFormat"
                :minute-interval="5"
                :input-width="extraSmallDevice ? '90px' : '120px'"
                input-class="schedules-input"
                close-on-complete
                @change="handlerClickListItem($event, day.key, timeIndex, field)"
              >
                <template v-slot:clearButton>
                  <v-icon class="subtitle-1 mr-m2">mdi-close</v-icon>
                </template>
              </VueTimepicker>
            </div>

            <!-- Divisor entre horas -->
            <span v-if="fieldIndex === 0" class="divider-time"> a </span>
          </div>
          <!-- Añadir turno -->
          <v-btn
            v-if="timeIndex === 0"
            class="vuetify-schedules-day__btns"
            icon
            @click="handlerAddTime(day.key)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- Borrar Turno -->
          <v-btn
            v-else
            icon
            class="vuetify-schedules-day__btns"
            @click="handlerDelTime(day.key, timeIndex)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VueTimepicker from 'vue2-timepicker'
// Vuex
import { mapState } from 'vuex'
// Utils
import { cloneDeep, get } from 'lodash'

export default {
  name: 'VuetifySchedules',
  components: {
    VueTimepicker
  },
  props: {
    // Etiqueta del checkbox cuando está cerrado
    closedDayCheckBoxLabel: {
      type: String,
      default: 'Cerrado'
    },
    // Etiqueta del día cuando no se muestran los campos
    closeDayCheckBoxLabel: {
      type: String,
      default: 'Cerrar'
    },
    // El estado de las horas y minutos inicial
    data: {
      type: Array,
      default() {
        return []
      }
    },
    // Los dias a mostrar
    days: {
      type: Array,
      default() {
        return [0, 1, 2, 3, 4, 5, 6]
      }
    },
    // El label de cada dia según su ID
    daysLabel: {
      type: Array,
      default() {
        return ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
      }
    },
    // El dia de inicio de semana
    dayStart: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      // Horario actual
      currentData: [],
      // Valor por defecto dentro de "data"
      timeFormat: 'HH:mm',
      defaultDataValue: {
        from: {
          HH: null,
          mm: null
        },
        to: {
          HH: null,
          mh: null
        }
      },
      // Dias de la semana desactivados
      disabledDays: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      },
      // Atruibutos a rellenar por cada turno
      timeFields: ['from', 'to'],
      // Atributos de los "timFields"
      timeFieldsParts: ['hh', 'm']
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice'])
  },
  watch: {
    disabledDays: {
      handler(value) {
        this.setNullValuesInCurrentData(value)
      },
      deep: true
    },
    currentData: {
      handler(value) {
        this.$emit('onUpdateTime', value)
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getEveryNeededData()
    })
  },
  methods: {
    /**
     * Se añade un turno más en el día
     *
     * @param {Number} day - dia de la semana
     */
    handlerAddTime(day) {
      const indexDay = this.currentData.findIndex((data) => {
        return data.key === day
      })

      if (indexDay > -1) {
        this.currentData[indexDay].data.push(cloneDeep(this.defaultDataValue))
      }
    },
    /**
     * Se elimina un turno del día
     *
     * @param {Number} day - dia de la semana
     * @param {Number} time - turno del día
     */
    handlerDelTime(day, time) {
      const indexDay = this.currentData.findIndex((data) => {
        return data.key === day
      })

      if (indexDay > -1) {
        this.currentData[indexDay].data.splice(time, 1)
      }
    },
    /**
     * Seteamos el valor seleccionado en el campo
     * correspondiente dentro
     *
     * @param {Number} day - dia de la semana
     * @param {Number} time - turno del día
     * @param {String} field - campo del turno
     * @param {String} type - "h" o "m"
     * @param {String} value - valor a almacenar
     */
    handlerClickListItem(eventData, day, time, field) {
      const indexDay = this.currentData.findIndex((data) => {
        return data.key === day
      })
      if (indexDay > -1) {
        this.currentData[indexDay].data[time][field] = {
          HH: eventData.data.HH || null,
          h: eventData.data.HH || null,
          mm: eventData.data.mm || null,
          m: eventData.data.mm || null
        }
      }
    },
    /**
     * Setea los valores iniciales según los datos
     * y los días indicados que deseamos mostrar
     */
    getEveryNeededData() {
      // Generamos un array con la ordenación correcta de los días
      const currentDays = Object.assign([], this.days)
      const dayIndex = currentDays.indexOf(this.dayStart)
      const reorderDays = [...currentDays.slice(dayIndex), ...currentDays.splice(0, dayIndex)]

      // Generamos el objeto inicial según los datos
      // pasados, así como indicar los días que están
      // marcados como "cerrados"
      reorderDays.map(async (day) => {
        // Buscamos en los datos actuales si
        // existe referencia a este día
        const currentDay = this.data.find((item) => {
          return item.key === day
        })
        // Seteamos valores iniciales
        const currenDayData = {
          key: day,
          data:
            typeof currentDay !== 'undefined'
              ? cloneDeep(currentDay.data)
              : [cloneDeep(this.defaultDataValue)]
        }
        const currentDataSet = await this.setTimeFormatByDayData(currenDayData)

        this.currentData.push(currentDataSet)
        // Días desactivados
        if (typeof currentDay !== 'undefined' && currentDay.data === null) {
          this.disabledDays[day] = true
        }
      })
    },
    /**
     * Seteamos el formato de la hora para adaptarlo
     * a la librería de gestión de horarios
     */
    async setTimeFormatByDayData(data) {
      if (data.data === null) return data
      const currentData = cloneDeep(data)
      const dataSet = currentData.data.reduce((sum, element) => {
        sum.push({
          from: {
            HH: element.from.h ? element.from.h : element.from.HH || null,
            mm: element.from.m ? element.from.m : element.from.mm || null
          },
          to: {
            HH: element.to.h ? element.to.h : element.to.HH || null,
            mm: element.to.m ? element.to.m : element.to.mm || null
          }
        })
        return sum
      }, [])
      const resData = {
        data: dataSet,
        key: currentData.key
      }
      return resData
    },
    /**
     * Seteamos los posibles NULL values in "CurrentData"
     *
     * @param {Object} disabledDays
     */
    setNullValuesInCurrentData(disabledDays) {
      // Recorro todos los días y modifico el valor de currentData
      Object.entries(disabledDays).forEach((day) => {
        this.currentData = this.currentData.map((data) => {
          if (data.key === Number.parseInt(day[0], 10)) {
            if (data.data === null && !day[1]) {
              data.data = cloneDeep([this.defaultDataValue]) // Modificamos el NULL por []
            } else if (day[1]) {
              data.data = null // Modificamos cualquier valor por NULL
            }
          }
          return data
        })
      })
    },
    /**
     * Pasado el ID del día devuelve el nombre de este
     *
     * @param {Number} value
     * @return {String}
     */
    getDayLabel(value) {
      return this.daysLabel[value]
    },
    /**
     * Obtenemos los items (horas o minustos) disponibles
     * según los turnos existentes en el día
     *
     * @param {Number} day - dia de la semana
     * @param {Number} time - turno del día
     * @param {String} field - campo del turno
     * @param {String} type - "h" o "m"
     */
    getAvailabeHoursOrMinutes(day, time, field, type = 'h') {
      // Tipo de elementos a listar
      const items = type === 'h' ? this.hours : this.minutes
      // Día seleccionado
      const currentDay = this.currentData.find((data) => {
        return data.key === day
      })

      // No encontramos el día
      if (!currentDay) return items

      // Datos del seleccionado
      const currentDayData = currentDay.data

      // El data de los días es NULL
      if (!currentDayData) return items

      // Datos del turno del día
      const previousDayTimeData = currentDayData[time - 1] // turno anterior al solicitado
      const currentDayTimeData = currentDayData[time] // turno actual solicitado

      // Obtenemos los datos a usar para la lógica
      // del siguiente bloque
      let dataHour = false
      let dataPreviousHour = false
      let dataPreviousMinute = false
      let indexDataPreviousMinute = false

      if (previousDayTimeData && currentDayTimeData && field === 'from') {
        // Con turno previo
        dataHour = get(currentDayTimeData, `from.h`, false)
        dataPreviousHour = get(previousDayTimeData, `to.h`, false)
        dataPreviousMinute = get(previousDayTimeData, `to.m`, false)
        indexDataPreviousMinute = this.minutes.findIndex((minute) => {
          return minute === dataPreviousMinute
        })
      } else if (currentDayTimeData && field === 'to') {
        // En el mismo turno
        dataHour = get(currentDayTimeData, `to.h`, false)
        dataPreviousHour = get(currentDayTimeData, `from.h`, false)
        dataPreviousMinute = get(currentDayTimeData, `from.m`, false)
        indexDataPreviousMinute = this.minutes.findIndex((minute) => {
          return minute === dataPreviousMinute
        })
      } else {
        return items
      }

      // Buscamos el valor por donde se debe empezar a contar
      let itemStartAt = 0
      if (type === 'h') {
        // HORAS
        // Si el selector de los minutos es el último de los valores,
        // deberemos saltar al siguiente item en las horas
        itemStartAt =
          dataPreviousMinute && this.minutes.indexOf(dataPreviousMinute) === this.minutes.length - 1
            ? Number.parseInt(dataPreviousHour, 10) + 1
            : dataPreviousHour || 0
      } else {
        // MINUTOS
        // Si el selector de los minutos es el último de los elementos(siendo la misma hora)
        // o distinta hora, la previo de la que estoy tratando, entonces,
        // los minutos deben volver a empezar, en caso contrario, se tomará
        // el siguiente item de minuto en el listado
        itemStartAt =
          indexDataPreviousMinute === -1 ||
          indexDataPreviousMinute === this.minutes.length - 1 ||
          dataHour !== dataPreviousHour
            ? this.minutes[0]
            : this.minutes[indexDataPreviousMinute + 1]
      }

      // Devolvemos items a completar el selector de la lista
      return items.filter((item) => {
        return Number.parseInt(item, 10) >= Number.parseInt(itemStartAt)
      })
    }
  }
}
</script>

<style lang="scss">
.schedules-input {
  height: 45px !important;
  background-color: #fff;
  font-size: 1.15em !important;
  border-radius: 4px;
}
.vuetify-schedules {
  width: 100%;
  .vuetify-schedules-day {
    background-color: $theme_background_dark;
    padding: 20px 20px;
    margin-bottom: 10px;
    width: 100%;
    .vuetify-schedules-day__top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label {
        font-size: 1.05rem;
        width: 90px;
      }
      // checkbox
      .v-input--selection-controls {
        padding-top: 0;
        margin-top: 0;
      }
    }
    .vuetify-schedules-day__bottom {
      max-width: 320px;
      width: 100%;
      margin-top: 0.7rem;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-top: 20px;
      .vuetify-schedules-day__times {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 0.5rem;
        .vuetify-schedules-day__fields {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .divider-time {
            line-height: 40px;
            width: 100%;
            padding: 0 2px;
            text-align: center;
          }
        }
        .vuetify-schedules-day__btns {
          margin-top: 5px;
          margin-left: -30px;
        }
      }
    }
  }
}
</style>

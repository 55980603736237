// Constantes
import { ADDONS, MENUS_TYPES } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifySchedules from '@/components/ui/VuetifySchedules'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Services
import {
  getEveryMenusByPlaceId,
  getAvailableCategoriesAndProductsByCategoryId
} from '@/services/category'
// Utils
import { get, cloneDeep, isNil } from 'lodash'

export default {
  name: 'TakeAwayConfigSchedule',
  components: {
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifyContentLoading,
    VuetifySchedules,
    VuetifyToolBar
  },
  mixins: [formMixin, uiMixin, validationMixin, addonsMixin],
  data() {
    return {
      // Form
      formFields: {
        takeAwayEnabled: false,
        takeAwaySchedule: []
      },
      formFieldsValidations: {},
      // Others
      canTakeAwayEnabledBeModified: false,
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * Evento lanzado tras la modificación del horario
     *
     * @param {Array} value - horarios
     */
    handleSchedule(value) {
      // establecemos nuevo valor en el horario
      this.formFields.takeAwaySchedule = value
    },
    /**
     * Establece los valores inciales del formulario
     */
    async getEveryNeededData() {
      await this.setCanTakeAwayEnabledBeModified()
      this.setFormFieldsValues()
    },
    /**
     * Comprobamos las categorías creadas en TAD y si
     * estas tienen al menos un producto
     */
    async setCanTakeAwayEnabledBeModified() {
      try {
        // Loading
        this.processingRequest = true

        let canBeEnabled = false

        const menus = await getEveryMenusByPlaceId(this.placeData.id)
        const tadMenu = menus.find((menu) => {
          return menu.type === MENUS_TYPES.takeAway.value
        })

        if (!isNil(tadMenu)) {
          const everyFromMenu = await getAvailableCategoriesAndProductsByCategoryId(tadMenu.id)
          // Comprobamos que haya al menos un producto en alguna
          // de las categorías
          canBeEnabled = everyFromMenu.some((menu) => {
            return menu.dishes.length > 0
          })
        }

        this.canTakeAwayEnabledBeModified = canBeEnabled
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const takeAwayConfig = get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})

      this.formFields = {
        takeAwayEnabled: this.canTakeAwayEnabledBeModified ? takeAwayConfig.takeAwayEnabled : false,
        takeAwaySchedule: cloneDeep(takeAwayConfig.takeAwaySchedule) || []
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Si no existe ninguna categoría o ningún producto dentro de la categoría
      if (!this.canTakeAwayEnabledBeModified) {
        this.formFields.takeAwayEnabled = false
      }
      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.takeAway,
            configFields: this.formFields
          }
        ]
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      takeAwayEnabled: {},
      takeAwaySchedule: {}
    }
  }
}
